let Globals = {
  uri: "https://proassist.ninjaslist.com/server/",
  //uri: "http://pa.local/server/",
  languageDefault: "pt-BR",
  facebookAppID: "743465542490755",

  isphone: false,

  constructor() {
    // are we running in native app or in a browser?
    if (
      document.URL.indexOf("http://") === -1 &&
      document.URL.indexOf("https://") === -1
    ) {
      this.isphone = true;
    }

    if (this.isphone) {
      //    document.addEventListener("deviceready", onDeviceReady, false);
    } else {
      //            onDeviceReady();
    }
  },

  // Current date time
  now() {
    var d = new Date();
    var month = d.getMonth() + 1;
    return (
      d.getFullYear() +
      "-" +
      month +
      "-" +
      d.getDate() +
      "T" +
      d.getHours() +
      ":" +
      d.getMinutes() +
      ":" +
      d.getSeconds()
    );
  },

  yyyymmdd(d) {
    var yyyy = d.getFullYear().toString();
    var mm = (d.getMonth() + 1).toString(); // getMonth() is zero-based
    var dd = d.getDate().toString();

    return (
      yyyy + "-" + (mm[1] ? mm : "0" + mm[0]) + "-" + (dd[1] ? dd : "0" + dd[0])
    );
  },

  DateAddDays(date, days) {
    var dat = new Date(date.valueOf());
    dat.setDate(dat.getDate() + days);
    return dat;
  },

  getTimeFromDateObject(d) {
    var returnValue =
      this.strZero(d.getHours(), 2) +
      ":" +
      this.strZero(d.getMinutes(), 2) +
      ":" +
      this.strZero(d.getSeconds(), 2);
    return returnValue;
  },

  daysBetween(date1, date2) {
    //Get 1 day in milliseconds
    var one_day = 1000 * 60 * 60 * 24;

    // Convert both dates to milliseconds
    var date1_ms = date1.getTime();
    var date2_ms = date2.getTime();

    // Calculate the difference in milliseconds
    var difference_ms = date2_ms - date1_ms;
    //take out milliseconds
    difference_ms = difference_ms / 1000;
    var seconds = Math.floor(difference_ms % 60);
    difference_ms = difference_ms / 60;
    var minutes = Math.floor(difference_ms % 60);
    difference_ms = difference_ms / 60;
    var hours = Math.floor(difference_ms % 24);
    var days = Math.floor(difference_ms / 24);

    return {
      days: days,
      hours: hours,
      minutes: minutes,
      seconds: seconds,
      difference: date2_ms - date1_ms,
    };
  },

  strZero(num, places) {
    var zero = places - num.toString().length + 1;
    return Array(+(zero > 0 && zero)).join("0") + num;
  },

  validateEmail(email) {
    var re = /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}/gim;
    if (email == "" || !re.test(email)) {
      return false;
    } else {
      return true;
    }
  },

  hideLoader() {
    let loader = document.getElementById("loader");
    if (loader) {
      loader.style.display = "none";
    }
  },
  showLoader() {
    let loader = document.getElementById("loader");
    if (loader) {
      loader.style.display = "block";
    }
  },

  splitTotalMinutes(sum) {
    var hours = Math.floor(sum / 60);
    var minutes = Math.round((sum / 60 - hours) * 60);

    return { hours: this.strZero(hours, 2), minutes: this.strZero(minutes, 2) };
  },

  /*
  onKeyValidateEmail(e) {
    var keynum;
    var keychar;
    var regEx;
    var allowedKeyNums = [8, 9, 35, 36, 46]; // Backspace, Tab, End, Home, (Delete & period)

    if (window.event)
      // IE
      keynum = e.keyCode;
    else if (e.which)
      // Netscape/Firefox/Opera
      keynum = e.which;
    else keynum = e.keyCode;

    keychar = String.fromCharCode(keynum);
    regEx = /[^$#<>?"']/; // Undesirable characters

    // Test for keynum values that collide with undesirable characters
    if ($.inArray(keynum, allowedKeyNums) > -1) return regEx.test(keychar);

    regEx = /[A-Z0-9a-z@_-]/;
    return regEx.test(keychar);
  }
  */
};

export default Globals;
