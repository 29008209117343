import Globals from "./globals.js";

let Languages = {
  getResource(code, language) {
    language =
      language != undefined
        ? language.substring(0, 2)
        : Globals.languageDefault.substring(0, 2);

    let codeResource = this.languageResources[code];
    if (codeResource) {
      return codeResource[language];
    } else {
      console.error("No language code: " + code);
      return null;
    }
  },

  languageResources: {
    ptBR: { pt: "Português", en: "Portuguese", es: "Portugues" },
    enUS: { pt: "Inglês", en: "English", es: "Ingles" },
    esES: { pt: "Espanhol", en: "Spanish", es: "Español" },
    accepted: { pt: "Aceitos em", en: "Accepted on", es: "Aceptado en" },
    not_accepted: {
      pt: "Ainda não aceitos",
      en: "Not accepted yet",
      es: "No aceptos todavia"
    },
    label_continue: { pt: "Próximo", en: "Continue", es: "Seguir" },
    label_back: { pt: "Voltar", en: "Back", es: "Volver" },
    label_subscribe: {
      pt: "Fazer Assinatura",
      en: "Subscribe",
      es: "Inscríbase"
    },

    photo: { pt: "Foto", en: "Photo", es: "Foto" },
    legal_agreement: {
      pt: "Termos e Condições",
      en: "Legal Agreement",
      es: "Ingles"
    },
    address: { pt: "Endereço", en: "Address", es: "Dirección" },
    bank: {
      pt: "Dados Financeiros",
      en: "Financial Data",
      es: "Datos Financieros"
    },
    cac: {
      pt: "Certidão de Antecedentes Criminais",
      en: "Criminal Records",
      es: "Información Criminal"
    },
    documents: { pt: "Documentos", en: "Documents", es: "Documentos" },
    people: {
      pt: "Dados Pessoais",
      en: "Personal Info",
      es: "Datos Personales"
    },
    phones: { pt: "Fones", en: "Phones", es: "Teléfonos" },
    professional: {
      pt: "Profissional",
      en: "Professional",
      es: "Profesional"
    },
    professional_references: {
      pt: "Referências Pessoais",
      en: "Personal Reference",
      es: "Referencias Personales"
    },
    professional_services: { pt: "Serviços", en: "Services", es: "Servicios" },
    professional_services_rate: {
      pt: "Pacotes de Serviços",
      en: "Service Packages",
      es: "Pacotes de Servicios"
    },
    resume: { pt: "Mini-Currículo", en: "Resume", es: "Curriculum" },
    skills: { pt: "Especialidades", en: "Skills", es: "Especialidades" },
    webaddress: {
      pt: "Endereços Web",
      en: "Web Addresses",
      es: "Direcciones Web"
    },
    login_success_false: {
      pt: "Solicitação de acesso sem sucesso",
      en: "Login success false",
      es: "Login success false"
    },
    login_failed: {
      pt: "Falha na tentativa de acesso",
      en: "Login fail",
      es: "Login fail"
    },
    home: { pt: "Início", en: "Home", es: "Inicio" },
    dashboard: { pt: "Painel", en: "Dashboard", es: "Tablero" },
    calendar: { pt: "Agenda", en: "Calendar", es: "Agenda" },
    contacts: { pt: "Contatos", en: "Contacts", es: "Contactos" },
    my_contacts: {
      pt: "Meus Contatos",
      en: "My Contacts",
      es: "Mis Contactos"
    },
    new_contacts: {
      pt: "Novos Contatos",
      en: "New Contacts",
      es: "Nuevos Contactos"
    },
    contact: { pt: "Contato", en: "Contact", es: "Contacto" },
    profile: { pt: "Perfil", en: "Profile", es: "Perfil" },
    work: { pt: "Trabalho", en: "Work", es: "Trabajo" },
    new: {
      pt: "Novo",
      en: "New",
      es: "Nuevo"
    },
    schedule: {
      pt: "Horário",
      en: "Schedule",
      es: "Horario"
    },
    my_work: {
      pt: "Meus Trabalhos",
      en: "My Work",
      es: "Mis Trabajos"
    },
    work_groups: {
      pt: "Serviços",
      en: "Work Groups",
      es: "Servicios"
    },
    work_group: {
      pt: "Serviço",
      en: "Work Group",
      es: "Servicio"
    },
    members: {
      pt: "Membros",
      en: "Members",
      es: "Miembros"
    },
    // Google Geoposition
    gmaps_unknown_error: {
      pt: "Não foi possível obter a sua localização",
      en: "Unable to find your location",
      es: "unknown_error"
    },
    gmaps_permission_denied: {
      pt: "A busca pela sua localização não foi permitida",
      en: "Permission denied in finding your location",
      es: "permission_denied"
    },
    gmaps_position_unavailable: {
      pt: "Sua localização atual é desconhecida",
      en: "Your location is currently unknown",
      es: "position_unavailable"
    },
    gmaps_break: {
      pt:
        "A tentativa de encontrar sua localização demorou mais que o esperado",
      en: "Attempt to find location took too long",
      es: "break"
    },
    gmaps_timeout: {
      pt:
        "A tentativa de encontrar sua localização demorou mais que o esperado",
      en: "The request to get user location timed out",
      es: "timeout"
    },
    gmaps_default: {
      pt: "A busca pela localização não é suportada por esta plataforma",
      en: "Location detection not supported in browser",
      es: "default"
    },

    welcome: { pt: "Seja bem-vindo", en: "Welcome", es: "Bienvenido" },
    signup: { pt: "Cadastre-se", en: "Sign Up", es: "Inscribirse" },
    password: { pt: "Senha", en: "Password", es: "Contraseña" },
    btnLogin: { pt: "Entrar", en: "Login", es: "Acceder" },
    btnLogout: { pt: "Sair", en: "Logout", es: "Salir" },
    btnEdit: { pt: "Editar", en: "Edit", es: "Editar" },
    btnExit: { pt: "Sair", en: "Exit", es: "Salir" },
    btnGenerateReport: {
      pt: "Gerar Relatório",
      en: "Generate Report",
      es: "Generar Informe"
    },
    register: { pt: "Cadastre-se", en: "Register", es: "Inscribirse" },
    confirm: { pt: "Confirmar", en: "Confirm", es: "Confirmar" },
    save: { pt: "Salvar", en: "Save", es: "Salvar" },
    firstName: { pt: "Nome", en: "First Name", es: "Nombre" },
    lastName: { pt: "Sobrenome", en: "Last Name", es: "Apellido" },
    familyName: { pt: "Sobrenome", en: "Family Name", es: "Apellido" },
    areaCode: { pt: "Area", en: "Area Code", es: "Area" },
    phoneNumber: { pt: "Telefone", en: "Phone Number", es: "Teléfono" },
    name: { pt: "Nome", en: "Name", es: "Nombre" },
    hour: { pt: "Hora", en: "Hour", es: "Hora" },
    day: { pt: "Dia", en: "Day", es: "Día" },
    month: { pt: "Mês", en: "Month", es: "Mes" },
    year: { pt: "Ano", en: "Year", es: "Año" },
    week: { pt: "Semana", en: "Week", es: "Semana" },

    payment_rate: {
      pt: "Custo",
      en: "Rate",
      es: "Coste"
    },
    professional_rate_hourly: {
      pt: "Custo Profissional por Hora",
      en: "Professional Rate Hourly",
      es: "Tarifa Profesional por Hora"
    },
    billing_type: {
      pt: "Tipo de Cobrança",
      en: "Billing Type",
      es: "Tipo de Facturación"
    },
    work_hours: {
      pt: "Horas de Trabalho",
      en: "Work Hours",
      es: "Horas de Trabajo"
    },
    billing_monthly: {
      pt: "Mês Fechado",
      en: "Monthly",
      es: "Por mes"
    },
    package: {
      pt: "Pacote",
      en: "Package",
      es: "Paquete"
    },
    packages: {
      pt: "Pacotes",
      en: "Packages",
      es: "Paquetes"
    },
    schedule_frequency: {
      pt: "Frequência de Agendamento",
      en: "Schedule Frequency",
      es: "Frecuencia de Programación"
    },
    last_schedule: {
      pt: "Último Agendamento",
      en: "Last Schedule",
      es: "Última Programación"
    },
    cancellation_period: {
      pt: "Horas Limite para Cancelamento",
      en: "Cancellation Hours",
      es: "Horas Límite de Cancelación"
    },
    rescheduling_limit: {
      pt: "Máximo de Dias para Reagendamento",
      en: "Maximun Days for Rescheduling",
      es: "Días Máximo para Reprogramación"
    },
    last_schedule: {
      pt: "Agendamento efetuado até",
      en: "Scheduled to",
      es: "Programación Efectuada Hasta"
    },
    invoice: {
      pt: "Nota Fiscal",
      en: "Invoice",
      es: "Factura"
    },
    send_invoice: {
      pt: "Emite Nota Fiscal",
      en: "Send Invoice",
      es: "Emite Factura"
    },

    cellPhoneNumber: {
      pt: "Número do Celular",
      en: "Mobile Number",
      es: "Numero del Celular"
    },
    registerNow: {
      pt: "Cadastre-se agora!",
      en: "Register Now!",
      es: "Registrate ahora!"
    },

    createYourAccount: {
      pt: "Crie sua conta",
      en: "Create your account",
      es: "Crea tu cuenta"
    },
    resetPassword: {
      pt: "Solicitar alteração da senha",
      en: "Request password change",
      es: "Solicitar cambio de contraseña"
    },
    loginTitle: {
      pt: "Realize seu login para continuar",
      en: "Sign In to your account",
      es: "Iniciar sesión en su cuenta"
    },
    forgotPassword: {
      pt: "Esqueceu sua senha?",
      en: "Forgot password?",
      es: "Se olvidó su contraseña?"
    },
    signupText: {
      pt:
        "Crie sua conta para interagir com seus clientes, pacientes, professores, treinadores, terapeutas, mentores, coachs e outros profissionais que te auxiliam a ter uma vida melhor.",
      en:
        "Sign up to interact with your clients, patients, teachers, trainers, therapists, mentors, coaches and other professionals that will help you to achieve a great life.",
      es:
        "Crie sua conta para interagir com seus clientes, pacientes, profesores, treinadores, terapeutas, mentores, entrenadores y profesionales de la vida útil."
    },
    noFacebookResponse: {
      pt: "Não houve resposta do Facebook",
      en: "No response from Facebook",
      es: "No hubo respuesta de Facebook"
    },
    noGoogleResponse: {
      pt: "Não houve resposta do Google",
      en: "No response from Google",
      es: "No hubo respuesta de Google"
    },
    peopleSearchBar: {
      pt:
        "Informe o nome, email ou telefone do contato que você está procurando",
      en: "Inform name, email or phone number from whom you are searching",
      es:
        "Informe el nombre, correo electrónico o número de teléfono de quien está buscando "
    },
    searchDatabasePromanager: {
      pt: "Buscar na base de dados ProManager",
      en: "Search in ProManager Database",
      es: "Buscar en la base de datos ProManager"
    },
    PersonNotFound: {
      pt: "Não encontrou quem estava procurando?",
      en: "example",
      es: "example"
    },
    notYetRegistered: {
      pt: "Ainda não está cadastrado?",
      en: "Not yet registered?",
      es: "Todavía no está registrado?"
    },
    orAccessUsing: {
      pt: "ou acesse usando",
      en: "or access using",
      es: "o acceda usando"
    },
    recurrent_day: {
      pt: "Dia",
      en: "Recurrent Day",
      es: "Dia"
    },
    time_before: {
      pt: "Horas antes",
      en: "Time before",
      es: "Tiempo antes"
    },
    send_by: {
      pt: "Enviar por",
      en: "Send by",
      es: "Enviar por"
    },
    send_to: {
      pt: "Enviar para",
      en: "Send to",
      es: "Enviar para"
    },
    description: {
      pt: "Descrição",
      en: "Description",
      es: "Descripción"
    },
    date: {
      pt: "Data",
      en: "Date",
      es: "Data"
    },
    time: {
      pt: "Hora",
      en: "Time",
      es: "Hora"
    },
    start: {
      pt: "Início",
      en: "Start",
      es: "Inicio"
    },
    end: {
      pt: "Término",
      en: "End",
      es: "Termino"
    },
    alerts: {
      pt: "Alertas",
      en: "Alerts",
      es: "Alertas"
    },
    alert: {
      pt: "Alerta",
      en: "Alert",
      es: "Alerta"
    },
    weekdays: {
      pt: [
        "domingo",
        "segunda",
        "terça",
        "quarta",
        "quinta",
        "sexta",
        "sábado"
      ],
      en: [
        "domingo",
        "segunda",
        "terça",
        "quarta",
        "quinta",
        "sexta",
        "sábado"
      ],
      es: ["domingo", "segunda", "terça", "quarta", "quinta", "sexta", "sábado"]
    },
    schedule_until: {
      pt: "Agendar até",
      en: "Schedule until",
      es: "Programar hasta"
    },
    scheduled: {
      pt: "Agendado",
      en: "Scheduled",
      es: "Programado"
    },
    accounts_receivable: {
      pt: "Contas a receber",
      en: "Accounts receivable",
      es: "Cuentas por cobrar"
    },
    until: {
      pt: "até",
      en: "until",
      es: "hasta"
    },
    appointments: {
      pt: "Compromissos",
      en: "Appointments ",
      es: "Citas"
    },
    appointment: {
      pt: "Compromisso",
      en: "Appointment ",
      es: "Cita"
    },
    reschedule: {
      pt: "Reagendamento",
      en: "Reschedule",
      es: "Reprogramación"
    },
    request_rescheduling: {
      pt: "Solicitar Reagendamento",
      en: "Request Rescheduling",
      es: "Solicitud de Reprogramación"
    },
    next_appointments: {
      pt: "Próximos Compromissos",
      en: "Next Appointments",
      es: "Próximas Citas"
    },
    rescheduling_in_approval: {
      pt: "Reagendamento em Aprovação",
      en: "Rescheduling in Approval",
      es: "Reactivación en aprobación"
    },
    to_insert: {
      pt: "Inserir",
      en: "Insert",
      es: "Añadir"
    },
    to_exclude: {
      pt: "Excluir",
      en: "Exclude",
      es: "Excluir"
    },
    to_schedule: {
      pt: "Agendar",
      en: "Schedule",
      es: "Programar"
    },
    to_accomplish: {
      pt: "Concluir",
      en: "Accomplish",
      es: "Cumplir"
    },
    to_request: {
      pt: "Solicitar",
      en: "Request",
      es: "Solicitar"
    },
    to_accept: {
      pt: "Aceitar",
      en: "Accept",
      es: "Aceptar"
    },
    to_reschedule: {
      pt: "Reagendar",
      en: "Reschedule",
      es: "Reprogramar"
    },
    to_refuse: {
      pt: "Recusar",
      en: "Refuse",
      es: "Rechazar"
    },
    to_receive: {
      pt: "Receber",
      en: "Receive",
      es: "Recibir"
    },
    to_cancel: {
      pt: "Cancelar",
      en: "Cancel",
      es: "Cancelar"
    },
    to_suspend: {
      pt: "Desmarcar",
      en: "Uncheck",
      es: "Desmarcar"
    },
    to_suggest: {
      pt: "Sugerir",
      en: "Suggest",
      es: "Sugerir"
    },
    update: {
      pt: "Alterar",
      en: "Update",
      es: "Actualizar"
    },
    appointment_status_SCH: {
      pt: "Agendado",
      en: "Scheduled",
      es: "Programado"
    },
    appointment_status_ACP: {
      pt: "Concluído",
      en: "Accomplhished",
      es: "Listo"
    },
    appointment_status_SUS: {
      pt: "Suspenso",
      en: "Suspended",
      es: "Suspendido"
    },
    appointment_status_RRQ: {
      pt: "Reagendamento Solicitado",
      en: "Reschedule Requested",
      es: "Reprogramación Solicitada"
    },
    appointment_status_RRF: {
      pt: "Reagendamento Recusado",
      en: "Rescheduling Refused",
      es: "Reprogramación Rechazada"
    },
    appointment_status_RAP: {
      pt: "Reagendamento Aprovado",
      en: "Rescheduling Approved",
      es: "Reprogramación Aprobada"
    },
    appointment_status_CNL: {
      pt: "Cancelado",
      en: "Canceled",
      es: "Cancelado"
    },
    appointment_status_DEL: {
      pt: "Excluído",
      en: "Excluded",
      es: "Excluido"
    },
    msg_cancellation_period_expired: {
      pt: "Prazo expirado para desmarcar o compromisso!",
      en: "Deadline expired to uncheck appointment!",
      es: "Plazo expirado para desmarcar la cita!"
    },
    msg_reschedule_request_sent: {
      pt: "Pedido de reagendamento enviado",
      en: "Reschedule request sent",
      es: "Solicitud de reprogramación enviada"
    },
    msg_appointment_updated: {
      pt: "Compromisso alterado com sucesso",
      en: "Appointment updated succesfully",
      es: "Cita cambiada con éxito"
    },
    msg_EMAIL_ALREADY_EXISTS: {
      pt: "Usuário já existente",
      en: "User already exists",
      es: "El usuario ya existe"
    },
    lbl_service: { pt: "Serviço", en: "Service", es: "Servicios" },
    lbl_title: { pt: "Título", en: "Title", es: "Título" },
    lbl_description: { pt: "Descrição", en: "Description", es: "Descripción" },
    lbl_appointments_update: {
      pt: "Atualização de Compromisso",
      en: "Uppointment Update",
      es: "Actualización de la Cita"
    },
    lbl_absences: {
      pt: "Ausências",
      en: "Absences",
      es: "Ausencias"
    },
    lbl_payment: {
      pt: "Pagamento",
      en: "Payment",
      es: "Pagamento"
    },
    lbl_payments: {
      pt: "Pagamentos",
      en: "Payments",
      es: "Pagamentos"
    },
    lbl_reports: {
      pt: "Relatórios",
      en: "Reports",
      es: "Reportes"
    },
    lbl_report_payment: {
      pt: "Pagamento de Profissionais",
      en: "Payroll of Professionals",
      es: "Nómina de Profesionales"
    },
    lbl_report_work_summary: {
      pt: "Resumo dos Serviços",
      en: "Work Summary",
      es: "Resumen de los Servicios"
    },
    lbl_financial: {
      pt: "Financeiro",
      en: "Financial",
      es: "Financiero"
    },
    lbl_accounts: {
      pt: "Contas",
      en: "Accounts",
      es: "Cuentas"
    },
    lbl_account: {
      pt: "Conta",
      en: "Account",
      es: "Cuenta"
    },
    lbl_status: {
      pt: "Situação",
      en: "Status",
      es: "Situación"
    },
    lbl_to_search: {
      pt: "Buscar",
      en: "Search",
      es: "Buscar"
    },
    lbl_bill: {
      pt: "Conta",
      en: "Bill",
      es: "Cuenta"
    },
    lbl_bills: {
      pt: "Contas",
      en: "Bills",
      es: "Cuentas"
    },
    lbl_payment_method: {
      pt: "Forma de Pagamento",
      en: "Payment Method",
      es: "Forma de Pago"
    },
    lbl_amount: {
      pt: "Valor",
      en: "Amount",
      es: "Valor"
    },
    lbl_reset_password: {
      pt: "Redefinir senha",
      en: "Reset password",
      es: "Redefinir contraseña"
    },
    lbl_number_of_appointments: {
      pt: "Número de Compromissos",
      en: "Number of Appointments",
      es: "Número de citas"
    },
    lbl_expiry: {
      pt: "Validade",
      en: "Expiry",
      es: "Expiración"
    },

    template: {
      pt: "example",
      en: "example",
      es: "example"
    },
    template: { pt: "example", en: "example", es: "example" }
  }
};

export default Languages;
