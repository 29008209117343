import React, { Component } from "react";
import GoogleLogin from "react-google-login";
import FacebookLogin from "react-facebook-login";
import userService from "../../../services/userService";
import { Redirect } from "react-router-dom";
import "./custom.css";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardGroup,
  Col,
  Container,
  Form,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row,
  NavLink,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Alert
} from "reactstrap";
import Languages from "../../../assets/js/languageResources.js";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      password: "",
      email: "",
      country_code: "55",
      area_code: "",
      number: "",
      redirect: false,
      responseLogin: null,
      modal: false,
      modalResetPassword: false,
      socialMedia: null
    };
    this.toggleModal = this.toggleModal.bind(this);
    this.toggleModalResetPassword = this.toggleModalResetPassword.bind(this);
    this.onChange = this.onChange.bind(this);
    this.handleKeyUp = this.handleKeyUp.bind(this);
  }

  componentDidMount() {
    //Logout
    userService.logout();
  }

  toggleModal() {
    this.setState({
      modal: !this.state.modal
    });
  }
  toggleModalResetPassword() {
    this.setState({
      modalResetPassword: !this.state.modalResetPassword
    });
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  handleKeyUp(event) {
    event.preventDefault();
    if (event.keyCode === 13) {
      document.getElementById("btnLogin").click();
    }
  }
  handleKeyDown(event) {
    if (event.keyCode === 13) {
      // Prevent submit a form when Enter is pressed
      event.preventDefault();
    }
  }

  render() {
    if (this.state.redirect) {
      return <Redirect to={"/dashboard"} />;
    }
    if (sessionStorage.getItem("user_data")) {
      return <Redirect to={"/dashboard"} />;
    }

    function showLoader() {
      document.getElementById("loader-login").style.display = "block";
    }
    function hideLoader() {
      document.getElementById("loader-login").style.display = "none";
    }

    const login = () => {
      if (this.state.email && this.state.password) {
        showLoader();
        userService
          .userLogin(this.state.email, this.state.password)
          .then(result => {
            //let responseJson = result;
            //localStorage.setItem("userData", JSON.stringify(responseJson));
            if (result.success) {
              this.setState({ redirect: true });
            } else {
              toast.error(result.error_msg);
            }
            console.log(result);
          })
          .catch(err => {
            toast.error(err.error_msg);
            console.error(err);
            hideLoader();
          });
      }
    };

    const responseGoogle = response => {
      showLoader();
      console.log(response);
      if (response.profileObj == undefined) {
        console.error("No response from Google");
        toast.error(
          Languages.getResource(
            "noGoogleResponse",
            localStorage.getItem("language")
          )
        );
        hideLoader();
      } else {
        // checkOauthLogin START
        userService
          .checkOauthLogin("googleID", response.profileObj.googleId, "")
          .then(
            resCheck => {
              // Se o registro pelo google ainda não existe irá fazer as validações necessárias
              if (resCheck.existsOauth == false) {
                hideLoader();
                this.setState({ responseLogin: response });
                this.setState({ email: response.profileObj.email });
                this.setState({ socialMedia: "G" });
                this.toggleModal();
              } else {
                processGoogle(response);
              }
            },
            error => {
              console.error("ERROR checkOauthLogin Data: ", error);
              toast.error("Erro ao verificar login pelo Google");
              hideLoader();
            }
          );
        // checkOauthLogin END
      }
    };

    const responseFacebook = responseLogin => {
      showLoader();
      console.log(responseLogin);
      if (responseLogin.accessToken == undefined) {
        console.error("No response from Facebook");
        toast.error(
          Languages.getResource(
            "noFacebookResponse",
            localStorage.getItem("language")
          )
        );
        hideLoader();
      } else {
        localStorage.fbAccessToken = responseLogin.accessToken;
        localStorage.fbExpiresIn = responseLogin.expiresIn;
        //console.log("Welcome!  Fetching your information.... ");

        // checkOauthLogin START
        userService.checkOauthLogin("facebookID", responseLogin.id, "").then(
          resCheck => {
            // Se o registro pelo facebook ainda não existe irá fazer as validações necessárias
            if (resCheck.existsOauth == false) {
              /*
              if (!this.loginData) {
                this.loginData = {};
              }						
              this.loginData.facebookID = response.id;
              this.loginData.email = (response.email != undefined) ? response.email : "";
              this.loginData.facebookEmail = (response.email != undefined) ? response.email : "";
              this.loginData.facebookAccessToken = localStorage.fbAccessToken;	
  */
              //if (responseLogin.email) {
              //  processFacebook(responseLogin, responseLogin.accessToken);
              //} else {
              hideLoader();
              this.setState({ responseLogin: responseLogin });
              this.setState({ email: responseLogin.email });
              this.setState({ socialMedia: "F" });
              this.toggleModal();
              //}
              //loader.dismiss();
            } else {
              processFacebook(responseLogin, responseLogin.accessToken);
              //loader.dismiss();
            }
          },
          error => {
            console.error("ERROR checkOauthLogin Data: ", error);
            toast.error("Erro ao verificar login pelo Facebook");
            hideLoader();
          }
        );
        // checkOauthLogin END
      }
    };

    // loginSocialMedia Start
    const loginSocialMedia = () => {
      const response = {
        ...this.state.responseLogin,
        email: this.state.email,
        country_code: this.state.country_code,
        area_code: this.state.area_code,
        number: this.state.number
      };

      if (this.state.socialMedia === "F") {
        processFacebook(response, response.accessToken);
      } else if (this.state.socialMedia === "G") {
        processGoogle(response);
      }
    };
    // loginSocialMedia END

    // processGoogle Start
    const processGoogle = params => {
      // Get the google login
      let th = this;
      userService.loginGoogle(params).then(
        function(response) {
          if (response.success) {
            /*console.log("Login efetuado com sucesso");
              let toast = toastCtrl.create({
                message: 'Login efetuado com sucesso',
                duration: 3000,
                position: 'bottom',
                cssClass: 'toastSuccess'
              });
              toast.present();   */
            th.setState({ redirect: true });
          } else {
            //console.error("Ocorreu um erro ao registrar o login pelo google.");
            toast.error("Ocorreu um erro ao registrar o login pelo google.");
            hideLoader();
          }
        },
        // Error on login
        function(response) {
          console.error(response);
          toast.error(response.error_msg, {
            position: toast.POSITION.TOP_LEFT
          });
          hideLoader();
        }
      );
    };
    // processGoogle END

    // processFacebook Start
    const processFacebook = (params, accessToken) => {
      //loader.present();
      // Get the facebook login
      let th = this;
      userService.loginFacebook(params, accessToken).then(
        function(response) {
          if (response.success) {
            console.log("Login efetuado com sucesso");
            /*let toast = toastCtrl.create({
              message: 'Login efetuado com sucesso',
              duration: 3000,
              position: 'bottom',
              cssClass: 'toastSuccess'
            });
            toast.present();   */
            //th.setState({ redirect: true });
            th.props.history.push("/");
          } else {
            console.error("Ocorreu um erro ao fazer login pelo facebook.");
            toast.error("Ocorreu um erro ao fazer login pelo facebook");
            hideLoader();
          }
        },
        // Error on login
        function(response) {
          console.error(response);
          toast.error(response.error_msg, {
            position: toast.POSITION.TOP_LEFT
          });
          hideLoader();
        }
      );
    };
    // processFacebook END

    // openResetPassword Start
    const openResetPassword = () => {
      this.toggleModalResetPassword();
    };
    // openResetPassword END

    // resetPassword Start
    const resetPassword = () => {
      if (this.state.email) {
        showLoader();
        userService
          .sendEmailResetPassword(this.state.email)
          .then(result => {
            //let responseJson = result;
            //localStorage.setItem("userData", JSON.stringify(responseJson));
            if (result) {
              toast.success(
                "O email para redefinição da senha foi enviado com sucesso!"
              );
            } else {
              toast.error(result.error_msg);
            }
            this.toggleModalResetPassword();
            hideLoader();
          })
          .catch(err => {
            toast.error(err.error_msg);
            console.error(err);
            hideLoader();
          });
      }
    };
    // resetPassword END

    return (
      <div>
        <div className="loader-holder" id="loader-login">
          <div className="loader-circle" />
        </div>
        <ToastContainer />

        <div className="app flex-row align-items-center">
          <Container>
            <Row className="justify-content-center">
              <Col md="8">
                <CardGroup>
                  <Card className="p-4">
                    <CardBody>
                      <Form>
                        <h1>
                          {Languages.getResource(
                            "welcome",
                            localStorage.language
                          )}
                        </h1>
                        <p className="text-muted">
                          {Languages.getResource(
                            "loginTitle",
                            localStorage.language
                          )}
                        </p>
                        <InputGroup className="mb-3">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="icon-envelope" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            type="text"
                            placeholder="Email"
                            autoComplete="email"
                            name="email"
                            onChange={this.onChange}
                            onKeyUp={this.handleKeyUp}
                          />
                        </InputGroup>
                        <InputGroup className="mb-4">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="icon-lock" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            type="password"
                            placeholder={Languages.getResource(
                              "password",
                              localStorage.language
                            )}
                            autoComplete="current-password"
                            name="password"
                            onChange={this.onChange}
                            onKeyUp={this.handleKeyUp}
                            onKeyDown={this.handleKeyDown}
                          />
                        </InputGroup>
                        <Row>
                          <Col xs="6">
                            <Button
                              color="primary"
                              className="px-4"
                              onClick={login}
                              id="btnLogin"
                            >
                              {Languages.getResource(
                                "btnLogin",
                                localStorage.language
                              )}
                            </Button>
                          </Col>
                          <Col xs="6" className="text-right">
                            <Button
                              color="link"
                              className="px-0"
                              onClick={openResetPassword}
                            >
                              {Languages.getResource(
                                "forgotPassword",
                                localStorage.language
                              )}
                            </Button>
                          </Col>
                        </Row>
                      </Form>
                    </CardBody>
                    <CardFooter>
                      <Row>
                        <Col xs="12" sm="12" className="text-center">
                          <p>
                            {Languages.getResource(
                              "orAccessUsing",
                              localStorage.language
                            )}
                          </p>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs="12" sm="12" className="text-center">
                          <FacebookLogin
                            appId="743465542490755"
                            autoLoad={false}
                            fields="id,name,first_name,last_name,gender,location,website,picture,relationship_status,birthday,email"
                            scope="public_profile" //"public_profile,user_friends"
                            callback={responseFacebook}
                            cssClass="btn-facebook mb-2 btn btn-secondary btn-lg"
                            icon="fa-facebook"
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col xs="12" sm="12" className="text-center">
                          <GoogleLogin
                            clientId="1075464083328-lit4garh6659e4o577kabrofe2b1gihh.apps.googleusercontent.com"
                            buttonText="Google"
                            onSuccess={responseGoogle}
                            onFailure={responseGoogle}
                          >
                            <i className="fa fa-google" />
                            <span
                              style={{
                                fontFamily: "system-ui",
                                fontWeight: "normal"
                              }}
                            >
                              {" "}
                              Login with Google
                            </span>
                          </GoogleLogin>
                        </Col>
                      </Row>
                    </CardFooter>
                  </Card>
                  <Card className="new-register">
                    <CardBody className="text-center">
                      {Languages.getResource(
                        "notYetRegistered",
                        localStorage.language
                      )}
                      <NavLink href="#/register">
                        <Button color="primary" className="mt-3" active>
                          {Languages.getResource(
                            "registerNow",
                            localStorage.language
                          )}
                        </Button>
                      </NavLink>
                    </CardBody>
                  </Card>
                  <Card
                    className="text-white bg-primary d-md-down-none py-5" // "d-md-down-none" (class used to show this only in @media (min-width: 576px) )
                    // style={{ width: 44 + "%" }}
                  >
                    <CardBody className="text-center">
                      <div>
                        <h2>
                          {Languages.getResource(
                            "signup",
                            localStorage.language
                          )}
                        </h2>
                        <p>
                          {Languages.getResource(
                            "signupText",
                            localStorage.language
                          )}
                        </p>
                        <NavLink href="#/register">
                          <Button color="primary" className="mt-3" active>
                            {Languages.getResource(
                              "registerNow",
                              localStorage.language
                            )}
                          </Button>
                        </NavLink>
                      </div>
                    </CardBody>
                  </Card>
                </CardGroup>
              </Col>
            </Row>
          </Container>
        </div>

        <Modal
          isOpen={this.state.modal}
          toggle={this.toggleModal}
          className={"modal-primary " + this.props.className}
        >
          <ModalHeader toggle={this.toggleModal}>
            {Languages.getResource("register", localStorage.language)}
          </ModalHeader>
          <ModalBody>
            <label>
              Informe seu e-mail e celular para concluir o cadastramento
            </label>
            <InputGroup className="mb-3">
              <InputGroupAddon addonType="prepend">
                <InputGroupText>@</InputGroupText>
              </InputGroupAddon>
              <Input
                type="text"
                name="email"
                placeholder="Email"
                autoComplete="email"
                value={this.state.email}
                onChange={this.onChange}
              />
            </InputGroup>
            <Row>
              <Col xs="5" style={{ paddingRight: "0px" }}>
                <InputGroup className="mb-3">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="icon-screen-smartphone" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    type="text"
                    name="area_code"
                    placeholder={Languages.getResource(
                      "areaCode",
                      localStorage.language
                    )}
                    autoComplete="areaCode"
                    onChange={this.onChange}
                  />
                </InputGroup>
                {/* areaCode */}
              </Col>
              <Col xs="7" style={{ paddingLeft: "0px" }}>
                <InputGroup className="mb-3">
                  <Input
                    type="text"
                    name="number"
                    placeholder={Languages.getResource(
                      "cellPhoneNumber",
                      localStorage.language
                    )}
                    autoComplete="number"
                    onChange={this.onChange}
                  />
                </InputGroup>
                {/* cellPhoneNumber */}
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button color="success" onClick={loginSocialMedia}>
              {Languages.getResource(
                "createYourAccount",
                localStorage.language
              )}
            </Button>
          </ModalFooter>
        </Modal>

        <Modal
          isOpen={this.state.modalResetPassword}
          toggle={this.toggleModalResetPassword}
          className={"modal-primary " + this.props.className}
        >
          <ModalHeader toggle={this.toggleModalResetPassword}>
            {Languages.getResource("register", localStorage.language)}
          </ModalHeader>
          <ModalBody>
            <label>Informe seu Email para redefirir sua senha</label>
            <InputGroup className="mb-3">
              <InputGroupAddon addonType="prepend">
                <InputGroupText>@</InputGroupText>
              </InputGroupAddon>
              <Input
                type="text"
                name="email"
                placeholder="Email"
                autoComplete="email"
                onChange={this.onChange}
              />
            </InputGroup>
            <Alert color="warning">
              <p>
                Você receberá um email com um link para recriar sua nova senha.
              </p>
              <p>
                Ao clicar neste link você será redirecionado para que possa
                criar uma nova senha.
              </p>
              <small>
                Caso você não execute este processo sua senha permanecerá a
                mesma de antes.
              </small>
            </Alert>
          </ModalBody>
          <ModalFooter>
            <Button color="warning" onClick={resetPassword}>
              {Languages.getResource("resetPassword", localStorage.language)}
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

export default Login;
