import React from "react";
import Loadable from "react-loadable";

import DefaultLayout from "./containers/DefaultLayout";

import Languages from "./assets/js/languageResources.js";

function Loading() {
  return <div>Loading...</div>;
}

const Breadcrumbs = Loadable({
  loader: () => import("./views/Base/Breadcrumbs"),
  loading: Loading
});

const Cards = Loadable({
  loader: () => import("./views/Base/Cards"),
  loading: Loading
});

const Carousels = Loadable({
  loader: () => import("./views/Base/Carousels"),
  loading: Loading
});

const Collapses = Loadable({
  loader: () => import("./views/Base/Collapses"),
  loading: Loading
});

const Dropdowns = Loadable({
  loader: () => import("./views/Base/Dropdowns"),
  loading: Loading
});

const Forms = Loadable({
  loader: () => import("./views/Base/Forms"),
  loading: Loading
});

const Jumbotrons = Loadable({
  loader: () => import("./views/Base/Jumbotrons"),
  loading: Loading
});

const ListGroups = Loadable({
  loader: () => import("./views/Base/ListGroups"),
  loading: Loading
});

const Navbars = Loadable({
  loader: () => import("./views/Base/Navbars"),
  loading: Loading
});

const Navs = Loadable({
  loader: () => import("./views/Base/Navs"),
  loading: Loading
});

const Paginations = Loadable({
  loader: () => import("./views/Base/Paginations"),
  loading: Loading
});

const Popovers = Loadable({
  loader: () => import("./views/Base/Popovers"),
  loading: Loading
});

const ProgressBar = Loadable({
  loader: () => import("./views/Base/ProgressBar"),
  loading: Loading
});

const Switches = Loadable({
  loader: () => import("./views/Base/Switches"),
  loading: Loading
});

const Tables = Loadable({
  loader: () => import("./views/Base/Tables"),
  loading: Loading
});

const Tabs = Loadable({
  loader: () => import("./views/Base/Tabs"),
  loading: Loading
});

const Tooltips = Loadable({
  loader: () => import("./views/Base/Tooltips"),
  loading: Loading
});

const BrandButtons = Loadable({
  loader: () => import("./views/Buttons/BrandButtons"),
  loading: Loading
});

const ButtonDropdowns = Loadable({
  loader: () => import("./views/Buttons/ButtonDropdowns"),
  loading: Loading
});

const ButtonGroups = Loadable({
  loader: () => import("./views/Buttons/ButtonGroups"),
  loading: Loading
});

const Buttons = Loadable({
  loader: () => import("./views/Buttons/Buttons"),
  loading: Loading
});

const Charts = Loadable({
  loader: () => import("./views/Charts"),
  loading: Loading
});

const Dashboard = Loadable({
  loader: () => import("./views/Dashboard"),
  loading: Loading
});

const CoreUIIcons = Loadable({
  loader: () => import("./views/Icons/CoreUIIcons"),
  loading: Loading
});

const Flags = Loadable({
  loader: () => import("./views/Icons/Flags"),
  loading: Loading
});

const FontAwesome = Loadable({
  loader: () => import("./views/Icons/FontAwesome"),
  loading: Loading
});

const SimpleLineIcons = Loadable({
  loader: () => import("./views/Icons/SimpleLineIcons"),
  loading: Loading
});

const Alerts = Loadable({
  loader: () => import("./views/Notifications/Alerts"),
  loading: Loading
});

const Badges = Loadable({
  loader: () => import("./views/Notifications/Badges"),
  loading: Loading
});

const Modals = Loadable({
  loader: () => import("./views/Notifications/Modals"),
  loading: Loading
});

const Colors = Loadable({
  loader: () => import("./views/Theme/Colors"),
  loading: Loading
});

const Typography = Loadable({
  loader: () => import("./views/Theme/Typography"),
  loading: Loading
});

const Widgets = Loadable({
  loader: () => import("./views/Widgets/Widgets"),
  loading: Loading
});

const Users = Loadable({
  loader: () => import("./views/Users/Users"),
  loading: Loading
});

const User = Loadable({
  loader: () => import("./views/Users/User"),
  loading: Loading
});

const Calendar = Loadable({
  loader: () => import("./views/Calendar"),
  loading: Loading
});

const Appointment = Loadable({
  loader: () => import("./views/Appointment"),
  loading: Loading
});

const Rescheduler = Loadable({
  loader: () => import("./views/Appointment/Rescheduler"),
  loading: Loading
});

const Schedule = Loadable({
  loader: () => import("./views/Appointment/Schedule"),
  loading: Loading
});

const Contacts = Loadable({
  loader: () => import("./views/Contacts"),
  loading: Loading
});

const NewContacts = Loadable({
  loader: () => import("./views/NewContacts"),
  loading: Loading
});

const Person = Loadable({
  loader: () => import("./views/Person"),
  loading: Loading
});

const Work = Loadable({
  loader: () => import("./views/Work"),
  loading: Loading
});

const WorkGroup = Loadable({
  loader: () => import("./views/WorkGroup"),
  loading: Loading
});

const Financial = Loadable({
  loader: () => import("./views/Financial/Financial"),
  loading: Loading
});

const FinancialBill = Loadable({
  loader: () => import("./views/Financial/Bill"),
  loading: Loading
});

const ReportPayment = Loadable({
  loader: () =>
    import("./views/Reports/ProfessionalPayment/ProfessionalPayment"),
  loading: Loading
});

const WorkSummary = Loadable({
  loader: () => import("./views/Reports/WorkSummary/WorkSummary"),
  loading: Loading
});

const Home = Loadable({
  loader: () => import("./views/Home"),
  loading: Loading
});

// https://github.com/ReactTraining/react-router/tree/master/packages/react-router-config
const routes = [
  {
    path: "/",
    exact: true,
    name: Languages.getResource("home", localStorage.language),
    component: DefaultLayout
  },
  {
    path: "/dashboard",
    name: Languages.getResource("dashboard", localStorage.language),
    component: Dashboard
  },
  {
    path: "/tutorial",
    name: Languages.getResource("home", localStorage.language),
    component: Home
  },
  { path: "/theme", exact: true, name: "Theme", component: Colors },
  { path: "/theme/colors", name: "Colors", component: Colors },
  { path: "/theme/typography", name: "Typography", component: Typography },
  { path: "/base", exact: true, name: "Base", component: Cards },
  { path: "/base/cards", name: "Cards", component: Cards },
  { path: "/base/forms", name: "Forms", component: Forms },
  { path: "/base/switches", name: "Switches", component: Switches },
  { path: "/base/tables", name: "Tables", component: Tables },
  { path: "/base/tabs", name: "Tabs", component: Tabs },
  { path: "/base/breadcrumbs", name: "Breadcrumbs", component: Breadcrumbs },
  { path: "/base/carousels", name: "Carousel", component: Carousels },
  { path: "/base/collapses", name: "Collapse", component: Collapses },
  { path: "/base/dropdowns", name: "Dropdowns", component: Dropdowns },
  { path: "/base/jumbotrons", name: "Jumbotrons", component: Jumbotrons },
  { path: "/base/list-groups", name: "List Groups", component: ListGroups },
  { path: "/base/navbars", name: "Navbars", component: Navbars },
  { path: "/base/navs", name: "Navs", component: Navs },
  { path: "/base/paginations", name: "Paginations", component: Paginations },
  { path: "/base/popovers", name: "Popovers", component: Popovers },
  { path: "/base/progress-bar", name: "Progress Bar", component: ProgressBar },
  { path: "/base/tooltips", name: "Tooltips", component: Tooltips },
  { path: "/buttons", exact: true, name: "Buttons", component: Buttons },
  { path: "/buttons/buttons", name: "Buttons", component: Buttons },
  {
    path: "/buttons/button-dropdowns",
    name: "Button Dropdowns",
    component: ButtonDropdowns
  },
  {
    path: "/buttons/button-groups",
    name: "Button Groups",
    component: ButtonGroups
  },
  {
    path: "/buttons/brand-buttons",
    name: "Brand Buttons",
    component: BrandButtons
  },
  { path: "/icons", exact: true, name: "Icons", component: CoreUIIcons },
  { path: "/icons/coreui-icons", name: "CoreUI Icons", component: CoreUIIcons },
  { path: "/icons/flags", name: "Flags", component: Flags },
  { path: "/icons/font-awesome", name: "Font Awesome", component: FontAwesome },
  {
    path: "/icons/simple-line-icons",
    name: "Simple Line Icons",
    component: SimpleLineIcons
  },
  {
    path: "/notifications",
    exact: true,
    name: "Notifications",
    component: Alerts
  },
  { path: "/notifications/alerts", name: "Alerts", component: Alerts },
  { path: "/notifications/badges", name: "Badges", component: Badges },
  { path: "/notifications/modals", name: "Modals", component: Modals },
  { path: "/widgets", name: "Widgets", component: Widgets },
  { path: "/charts", name: "Charts", component: Charts },
  { path: "/users", exact: true, name: "Users", component: Users },
  { path: "/users/:id", exact: true, name: "User Details", component: User },

  {
    path: "/calendar",
    name: Languages.getResource("calendar", localStorage.language),
    component: Calendar
  },
  {
    path: "/appointment/:id",
    exact: true,
    name: Languages.getResource("appointment", localStorage.language),
    component: Appointment
  },
  {
    path: "/appointment/rescheduler/:id/:action",
    exact: true,
    name: Languages.getResource("to_reschedule", localStorage.language),
    component: Rescheduler
  },
  {
    path: "/appointmentschedule",
    exact: true,
    name: Languages.getResource("to_schedule", localStorage.language),
    component: Schedule
  },  
  {
    path: "/contacts",
    exact: true,
    name: Languages.getResource("contacts", localStorage.language),
    component: Contacts
  },
  {
    path: "/contacts/mycontacts",
    name: Languages.getResource("my_contacts", localStorage.language),
    component: Contacts
  },
  {
    path: "/contacts/newcontacts",
    name: Languages.getResource("new_contacts", localStorage.language),
    component: NewContacts
  },
  {
    path: "/person/:id",
    name: Languages.getResource("contact", localStorage.language),
    component: Person
  },
  {
    path: "/workgroup",
    exact: true,
    name: Languages.getResource("work_groups", localStorage.language),
    component: WorkGroup
  },
  {
    path: "/workgroup/work/:id",
    name: Languages.getResource("work_group", localStorage.language),
    component: Work
  },
  {
    path: "/financial",
    exact: true,
    name: Languages.getResource("lbl_financial", localStorage.language),
    component: Financial
  },
  {
    path: "/financial/bill",
    name: Languages.getResource("lbl_bills", localStorage.language),
    component: FinancialBill
  },
  {
    path: "/reports",
    exact: true,
    name: Languages.getResource("lbl_reports", localStorage.language),
    component: ReportPayment
  },
  {
    path: "/reports/payment",
    name: Languages.getResource("lbl_report_payment", localStorage.language),
    component: ReportPayment
  },
  {
    path: "/reports/worksummary",
    name: Languages.getResource(
      "lbl_report_work_summary",
      localStorage.language
    ),
    component: WorkSummary
  }
];

export default routes;
