import React, { Component } from "react";
import GoogleLogin from "react-google-login";
import FacebookLogin from "react-facebook-login";
import userService from "../../../services/userService";
import { Redirect } from "react-router-dom";
import "./custom.css";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  Col,
  Container,
  Form,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row
} from "reactstrap";
import Languages from "../../../assets/js/languageResources.js";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
/* Import Components */
import InputText from "../../../components/InputText";

class Register extends Component {
  constructor(props) {
    super(props);
    this.state = {
      firstName: "",
      lastName: "",
      password: "",
      passwordConfirm: "",
      email: "",
      country_code: "55",
      area_code: "",
      number: "",
      redirect: false
    };
    this.signup = this.signup.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  showLoader() {
    document.getElementById("loader-login").style.display = "block";
  }
  hideLoader() {
    document.getElementById("loader-login").style.display = "none";
  }

  signup() {
    if (
      this.state.firstName &&
      this.state.lastName &&
      this.state.password &&
      this.state.passwordConfirm &&
      this.state.email &&
      this.state.area_code &&
      this.state.number
    ) {
      this.showLoader();
      userService
        .userRegister(
          this.state.email,
          this.state.firstName,
          this.state.lastName,
          this.state.country_code,
          this.state.area_code,
          this.state.number,
          this.state.password,
          this.state.passwordConfirm,
          true,
          localStorage.getItem("user_id")
        )
        .then(result => {
          //let responseJson = result;
          //localStorage.setItem("userData", JSON.stringify(responseJson));
          this.login();
        })
        .catch(err => {
          console.error(err);
          toast.error(err.error_msg);
          this.hideLoader();
        });
    }
  }

  login() {
    if (this.state.email && this.state.password) {
      userService
        .userLogin(this.state.email, this.state.password)
        .then(result => {
          //let responseJson = result;
          //localStorage.setItem("userData", JSON.stringify(responseJson));
          this.setState({ redirect: true });
        })
        .catch(err => {
          console.error(err);
          toast.error(err.error_msg);
          this.hideLoader();
        });
    }
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  render() {
    if (this.state.redirect) {
      return <Redirect to={"/"} />;
    }

    if (sessionStorage.getItem("user_data")) {
      return <Redirect to={"/"} />;
    }

    const responseGoogle = response => {
      this.showLoader();
      console.log(response);
      let th = this;
      userService.loginGoogle(response.profileObj).then(
        function(response) {
          if (response.success) {
            console.log("Login efetuado com sucesso");
            th.setState({ redirect: true });
          } else {
            console.error("Ocorreu um erro ao fazer login pelo google.");
            /* loader.dismiss();*/
          }
        },
        // Error on login
        function(response) {
          console.error(response);
          /*loader.dismiss();*/
        }
      );
      // processFacebook END
    };

    const responseFacebook = responseLogin => {
      this.showLoader();
      console.log(responseLogin);
      localStorage.fbAccessToken = responseLogin.access_token;
      localStorage.fbExpiresIn = responseLogin.expires_in;
      console.log("Welcome!  Fetching your information.... ");

      // checkFacebookLogin START
      userService.checkFacebookLogin(responseLogin.id, "").then(
        resCheck => {
          // Se o registro pelo facebook ainda não existe irá fazer as validações necessárias
          if (resCheck) {
            if (!resCheck.existsFacebookID) {
              /*
            if (!this.loginData) {
              this.loginData = {};
            }						
            this.loginData.facebookID = response.id;
            this.loginData.email = (response.email != undefined) ? response.email : "";
            this.loginData.facebookEmail = (response.email != undefined) ? response.email : "";
            this.loginData.facebookAccessToken = localStorage.fbAccessToken;	
*/
              processFacebook(responseLogin, responseLogin.accessToken);
              //loader.dismiss();
            } else {
              processFacebook(responseLogin, responseLogin.accessToken);
              //loader.dismiss();
            }
          } else {
            processFacebook(responseLogin, responseLogin.accessToken);
            //loader.dismiss();
          }
        },
        error => {
          console.log("ERROR checkFacebookLogin Data: ", error);
          //loader.dismiss();
        }
      );
      // checkFacebookLogin END
    };

    // processFacebook Start
    const processFacebook = (params, accessToken) => {
      //loader.present();
      // Get the facebook login
      let th = this;
      userService.loginFacebook(params, accessToken).then(
        function(response) {
          if (response.success) {
            console.log("Login efetuado com sucesso");
            /*let toast = toastCtrl.create({
              message: 'Login efetuado com sucesso',
              duration: 3000,
              position: 'bottom',
              cssClass: 'toastSuccess'
            });
            toast.present();   */
            th.setState({ redirect: true });
          } else {
            console.error("Ocorreu um erro ao fazer login pelo facebook.");
            toast.error("Ocorreu um erro ao fazer login pelo facebook");
            this.hideLoader();
          }
        },
        // Error on login
        function(response) {
          console.error(response);
          toast.error(response.error_msg, {
            position: toast.POSITION.TOP_LEFT
          });
          this.hideLoader();
        }
      );
    };
    // processFacebook END

    return (
      <div>
        <div className="loader-holder" id="loader-login">
          <div className="loader-circle" />
        </div>
        <ToastContainer />
        <div className="app flex-row align-items-center">
          <Container>
            <Row className="justify-content-center">
              <Col md="6">
                <Card className="mx-4">
                  <CardBody className="p-4">
                    <Form>
                      <h1>
                        {Languages.getResource(
                          "register",
                          localStorage.language
                        )}
                      </h1>
                      <p className="text-muted">
                        {Languages.getResource(
                          "createYourAccount",
                          localStorage.language
                        )}
                      </p>
                      <InputGroup className="mb-3">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="icon-user" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          type="text"
                          name="firstName"
                          placeholder={Languages.getResource(
                            "firstName",
                            localStorage.language
                          )}
                          autoComplete="username"
                          onChange={this.onChange}
                        />
                      </InputGroup>
                      <InputGroup className="mb-3">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="icon-user" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          type="text"
                          name="lastName"
                          placeholder={Languages.getResource(
                            "lastName",
                            localStorage.language
                          )}
                          autoComplete="username"
                          onChange={this.onChange}
                        />
                      </InputGroup>
                      <InputGroup className="mb-3">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>@</InputGroupText>
                        </InputGroupAddon>
                        <Input
                          type="text"
                          name="email"
                          placeholder="Email"
                          autoComplete="email"
                          onChange={this.onChange}
                        />
                      </InputGroup>
                      <Row>
                        <Col xs="5" style={{ paddingRight: "0px" }}>
                          <InputGroup className="mb-3">
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <i className="icon-screen-smartphone" />
                              </InputGroupText>
                            </InputGroupAddon>
                            <Input
                              type="text"
                              name="area_code"
                              placeholder={Languages.getResource(
                                "areaCode",
                                localStorage.language
                              )}
                              autoComplete="areaCode"
                              onChange={this.onChange}
                            />
                          </InputGroup>
                          {/* areaCode */}
                        </Col>
                        <Col xs="7" style={{ paddingLeft: "0px" }}>
                          <InputGroup className="mb-3">
                            <Input
                              type="text"
                              name="number"
                              placeholder={Languages.getResource(
                                "cellPhoneNumber",
                                localStorage.language
                              )}
                              autoComplete="number"
                              onChange={this.onChange}
                            />
                          </InputGroup>
                          {/* cellPhoneNumber */}
                        </Col>
                      </Row>
                      <InputGroup className="mb-3">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="icon-lock" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          type="password"
                          name="password"
                          placeholder={Languages.getResource(
                            "password",
                            localStorage.language
                          )}
                          autoComplete="new-password"
                          onChange={this.onChange}
                        />
                      </InputGroup>
                      <InputGroup className="mb-4">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="icon-lock" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          type="password"
                          name="passwordConfirm"
                          placeholder={
                            Languages.getResource(
                              "confirm",
                              localStorage.language
                            ) +
                            " " +
                            Languages.getResource(
                              "password",
                              localStorage.language
                            )
                          }
                          autoComplete="new-password"
                          onChange={this.onChange}
                        />
                      </InputGroup>
                      <Button color="success" block onClick={this.signup}>
                        {Languages.getResource(
                          "createYourAccount",
                          localStorage.language
                        )}
                      </Button>
                    </Form>
                  </CardBody>
                  <CardFooter>
                    <Row>
                      <Col xs="12" sm="12" className="text-center">
                        <p>
                          {Languages.getResource(
                            "orAccessUsing",
                            localStorage.language
                          )}
                        </p>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs="12" sm="12" className="text-center">
                        <FacebookLogin
                          appId="743465542490755"
                          autoLoad={false}
                          fields="id,name,first_name,last_name,gender,location,website,picture,relationship_status,birthday,email"
                          scope="public_profile,user_friends"
                          callback={responseFacebook}
                          cssClass="btn-facebook mb-2 btn btn-secondary btn-lg"
                          icon="fa-facebook"
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col xs="12" sm="12" className="text-center mb-2">
                        <GoogleLogin
                          clientId="1075464083328-lit4garh6659e4o577kabrofe2b1gihh.apps.googleusercontent.com"
                          buttonText="Google"
                          onSuccess={responseGoogle}
                          onFailure={responseGoogle}
                        >
                          <i className="fa fa-google" />
                          <span
                            style={{
                              fontFamily: "system-ui",
                              fontWeight: "normal"
                            }}
                          >
                            {" "}
                            Login with Google
                          </span>
                        </GoogleLogin>
                      </Col>
                    </Row>
                  </CardFooter>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    );
  }
}

export default Register;
