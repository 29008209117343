import SecureHashAlgorithm from "../assets/js/sha512.js";
import Globals from "../assets/js/globals.js";

let userService = {
  // ===== login
  userLogin(email, password) {
    return new Promise((resolve, reject) => {
      // Check each field has a value
      if (email === "" || password === "") {
        this.error_msg = "Você deve informar o usuário e a senha";
        this.success = false;
        reject(this);
      }

      let globals = Globals;
      let sha512 = SecureHashAlgorithm;

      let p = sha512.hex_sha512(password);
      //var p = password;

      let searchParams = new URLSearchParams();
      searchParams.append("action", "login");
      searchParams.append("email", email);
      searchParams.append("p", p);

      let BaseUrl = globals.uri + "common/api/user.php";

      fetch(BaseUrl, {
        method: "POST",
        body: searchParams,
        headers: {
          "Content-Type": "application/x-www-form-urlencoded"
        },
        credentials: "same-origin"
      })
        .then(response => response.json())
        .then(responseJson => {
          // Success
          // this.success = response.success;
          if (responseJson.success) {
            localStorage.setItem("token", responseJson.token.token);
            localStorage.user_id = responseJson.token.user_id;
            localStorage.expiration = responseJson.token.expiration;
            localStorage.language = responseJson.user.language;
            localStorage.user_data = this.getUserData(responseJson);
            localStorage.person_id = responseJson.person.id;
            resolve(responseJson);
          } else {
            reject(responseJson);
          }
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  // ===== login END

  // ===== getUserData
  getUserData(response) {
    var avatar =
      response.user.picture_url !== ""
        ? response.user.picture_url
        : "assets/img/avatars/user@2x.png";
    var user_data = {
      logged: true,
      name: response.person.first_name,
      avatar: avatar,
      alerts: [],
      messages: []
    };

    return JSON.stringify(user_data);
  },
  // ===== getUserData END

  // ===== userRegister
  userRegister(
    email,
    firstname,
    lastname,
    country_code,
    area_code,
    number,
    password,
    passwordConfirm,
    logout,
    user_id,
    hostKeyField
  ) {
    let promise = new Promise((resolve, reject) => {
      this.error_msgs = [];

      let validation = true;

      // Check each field has a value
      if (!email || !firstname || !lastname || !password || !passwordConfirm) {
        this.error_msgs.push("Por favor preencha todos os campos");
        validation = false;
      }

      /*
      // Check the username
      re = /^\w+$/; 
      if(!re.test(form.username.value)) { 
          alert("Username must contain only letters, numbers and underscores. Please try again"); 
          form.username.focus();
          return false; 
      }
      */

      if (password) {
        // Check that the password is sufficiently long (min 6 chars)
        // The check is duplicated below, but this is included to give more
        // specific guidance to the user
        if (password.length < 6) {
          this.error_msgs.push("A senha deve ter ao menos 6 caracteres.");
          validation = false;
        }

        // At least one number, one lowercase and one uppercase letter
        // At least six characters
        /*
        var re = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,}/; 
        if (!re.test(password)) {
            alert('Passwords must contain at least one number, one lowercase and one uppercase letter.  Please try again');
            return false;
        }
        */

        // Check password and confirmation are the same
        if (password !== passwordConfirm) {
          this.error_msgs.push("As senhas não conferem.");
          validation = false;
        }
      }

      if (validation) {
        let globals = Globals;
        let sha512 = SecureHashAlgorithm;

        let p = sha512.hex_sha512(password);

        let body = new URLSearchParams();
        if (user_id) {
          body.append("action", "register");
          body.append("addressid", "1");
          body.append("username", email);
          body.append("email", email);
          body.append("firstname", firstname);
          body.append("lastname", lastname);
          body.append("country_code", country_code);
          body.append("area_code", area_code);
          body.append("number", number);
          body.append("p", p);
          body.append("hostid", user_id);
          body.append("hostkeyfield", hostKeyField ? hostKeyField : null);
        } else {
          body.append("action", "register");
          body.append("addressid", "1");
          body.append("username", email);
          body.append("email", email);
          body.append("firstname", firstname);
          body.append("lastname", lastname);
          body.append("country_code", country_code);
          body.append("area_code", area_code);
          body.append("number", number);
          body.append("p", p);
        }

        if (logout) {
          this.logout();
        }

        let BaseUrl = globals.uri + "common/api/user.php";

        fetch(BaseUrl, {
          method: "POST",
          body: body.toString(),
          headers: {
            "Content-Type": "application/x-www-form-urlencoded"
          },
          credentials: "same-origin"
        })
          .then(response => response.json())
          .then(responseJson => {
            // Success
            if (responseJson.success) {
              resolve(responseJson);
            } else {
              responseJson.error_msg = "Falha no cadastro";
              reject(responseJson);
            }
          })
          .catch(error => {
            reject(error);
          });
      } else {
        var error_msg = "";

        this.error_msgs.forEach(function(item, index) {
          error_msg += item + "\r\n";
        });
        this.error_msg = error_msg;
        reject({ error_msg: this.error_msg });
      }
    });
    return promise;
  },
  // ===== userRegister END

  // ===== logout
  logout() {
    /*if ( localStorage.hasOwnProperty('fbAccessToken') ) {
      FB.logout(function(response) {
          // user is now logged out
          console.log("user is now logged out");
      });	
    }		*/

    localStorage.clear();
    return;
  },
  // ===== logout END

  // ===== getFacebookData START
  // OBSOLETE - Não é mais necessário pois o login feito pelo
  // React já retorna todos os dados
  getFacebookData(access_token) {
    let promise = new Promise((resolve, reject) => {
      const url = "https://graph.facebook.com/v2.2/me";
      const params = {
        params: {
          access_token: access_token,
          fields:
            "id,name,first_name,last_name,gender,location,website,picture,relationship_status,birthday,email",
          format: "json"
        }
      };
      const urlParams = new URLSearchParams(Object.entries(params));

      fetch(url + urlParams)
        .then(response => response.json())
        .then(responseJson => {
          // Success
          resolve(responseJson);
        })
        .catch(error => {
          reject(error);
        });
    });
    return promise;
  },
  // ===== getFacebookData END

  // ===== checkFacebookLogin START
  checkFacebookLogin(facebookID, username) {
    let globals = Globals;

    let promise = new Promise((resolve, reject) => {
      const body = {
        action: "checkFacebookLogin",
        facebookID: facebookID,
        username: username
      };

      let BaseUrl = globals.uri + "common/api/userJson.php";

      fetch(BaseUrl, {
        method: "POST",
        body: JSON.stringify(body)
      })
        .then(response => response.json())
        .then(responseJson => {
          // Success
          resolve(responseJson);
        })
        .catch(error => {
          reject(error);
        });
    });
    return promise;
  },
  // ===== checkFacebookLogin END

  // ===== checkOauthLogin START
  checkOauthLogin(fieldName, oauthID, username) {
    let globals = Globals;

    let promise = new Promise((resolve, reject) => {
      const body = {
        action: "checkOauthLogin",
        fieldName: fieldName,
        oauthID: oauthID,
        username: username
      };

      let BaseUrl = globals.uri + "common/api/userJson.php";

      fetch(BaseUrl, {
        method: "POST",
        body: JSON.stringify(body)
      })
        .then(response => response.json())
        .then(responseJson => {
          // Success
          resolve(responseJson);
        })
        .catch(error => {
          reject(error);
        });
    });
    return promise;
  },
  // ===== checkOauthLogin END

  // ===== loginFacebook START
  loginFacebook(params, facebook_access_token) {
    let globals = Globals;
    let getUserData = this.getUserData;

    let promise = new Promise((resolve, reject) => {
      var email = params.email;
      var country_code = params.country_code;
      var area_code = params.area_code;
      var number = params.number;
      var first_name = params.first_name !== undefined ? params.first_name : "";
      var last_name = params.last_name !== undefined ? params.last_name : "";
      var gender = params.gender !== undefined ? params.gender : "";
      var relationship_status =
        params.relationship_status !== undefined
          ? params.relationship_status
          : "";
      var picture = "";
      if (params.picture !== undefined)
        if (params.picture.data !== undefined)
          picture =
            params.picture.data.url !== undefined
              ? params.picture.data.url
              : "";
      var birthday = params.birthday !== undefined ? params.birthday : "";
      var location = "";
      if (params.location !== undefined)
        location =
          params.location.name !== undefined ? params.location.name : "";
      var facebookID = params.id !== undefined ? params.userID : "";
      var username =
        params.username !== undefined ? params.username : params.email;

      let body = new URLSearchParams();
      body.append("action", "loginFacebook");
      body.append("username", username !== undefined ? username : "");
      body.append("first_name", first_name);
      body.append("last_name", last_name);
      body.append("email", email !== undefined ? email : "");
      body.append(
        "country_code",
        country_code !== undefined ? country_code : ""
      );
      body.append("area_code", area_code !== undefined ? area_code : "");
      body.append("number", number !== undefined ? number : "");
      body.append("gender", gender);
      body.append("relationship_status", relationship_status);
      body.append("picture_url", picture);
      body.append("birthday", birthday);
      body.append("location", location);
      body.append("facebookID", facebookID);
      body.append("facebook_access_token", facebook_access_token);

      let BaseUrl = globals.uri + "common/api/user.php";

      fetch(BaseUrl, {
        method: "POST",
        body: body.toString(),
        headers: {
          "Content-Type": "application/x-www-form-urlencoded"
        },
        credentials: "same-origin"
      })
        .then(response => response.json())
        .then(responseJson => {
          // Success
          if (responseJson.success) {
            localStorage.token = responseJson.token.token;
            localStorage.user_id = responseJson.token.user_id;
            localStorage.expiration = responseJson.token.expiration;
            localStorage.language = responseJson.user.language;
            localStorage.user_data = getUserData(responseJson);
            localStorage.person_id = responseJson.person.id;
            console.log(
              "User sucessfully registered\nPlease proceed with your login."
            );
            resolve(responseJson);
          } else {
            reject({ error_msg: responseJson.error_msg });
          }
        })
        .catch(error => {
          reject(error);
        });
    });
    return promise;
  },
  // ===== loginFacebook END

  // ===== loginGoole START
  loginGoogle(params) {
    let globals = Globals;
    let getUserData = this.getUserData;

    let promise = new Promise((resolve, reject) => {
      let email =
        params.profileObj.email !== undefined ? params.profileObj.email : "";
      let country_code = params.country_code;
      let area_code = params.area_code;
      let number = params.number;
      let first_name =
        params.profileObj.givenName !== undefined
          ? params.profileObj.givenName
          : "";
      let last_name =
        params.profileObj.familyName !== undefined
          ? params.profileObj.familyName
          : "";
      let picture_url =
        params.profileObj.imageUrl !== undefined
          ? params.profileObj.imageUrl
          : "";
      let googleID =
        params.profileObj.googleId !== undefined
          ? params.profileObj.googleId
          : "";

      let body = new URLSearchParams();
      body.append("action", "loginGoogle");
      body.append("first_name", first_name);
      body.append("last_name", last_name);
      body.append("email", email);
      body.append(
        "country_code",
        country_code !== undefined ? country_code : ""
      );
      body.append("area_code", area_code !== undefined ? area_code : "");
      body.append("number", number !== undefined ? number : "");
      body.append("picture_url", picture_url);
      body.append("googleID", googleID);

      let BaseUrl = globals.uri + "common/api/user.php";

      fetch(BaseUrl, {
        method: "POST",
        body: body.toString(),
        headers: {
          "Content-Type": "application/x-www-form-urlencoded"
        },
        credentials: "same-origin"
      })
        .then(response => response.json())
        .then(responseJson => {
          // Success
          if (responseJson.success) {
            localStorage.token = responseJson.token.token;
            localStorage.user_id = responseJson.token.user_id;
            localStorage.language = responseJson.user.language;
            localStorage.user_data = getUserData(responseJson);
            localStorage.person_id = responseJson.person.id;
            console.log(
              "User sucessfully registered\nPlease proceed with your login."
            );
            resolve(responseJson);
          } else {
            reject({ error_msg: responseJson.error_msg });
          }
        })
        .catch(error => {
          reject(error);
        });
    });
    return promise;
  },
  // ===== loginGoole END

  // ===== sendEmailResetPassword START
  sendEmailResetPassword(email) {
    let globals = Globals;

    let promise = new Promise((resolve, reject) => {
      let body = new URLSearchParams();
      body.append("action", "sendEmailResetPassword");
      body.append("email", email);

      let BaseUrl = globals.uri + "common/api/user.php";

      fetch(BaseUrl, {
        method: "POST",
        body: body.toString(),
        headers: {
          "Content-Type": "application/x-www-form-urlencoded"
        },
        credentials: "same-origin"
      })
        .then(response => response.json())
        .then(responseJson => {
          // Success
          if (responseJson.success) {
            resolve(responseJson);
          } else {
            reject(responseJson);
          }
        })
        .catch(error => {
          reject(error);
        });
    });
    return promise;
  },
  // ===== sendEmailResetPassword END

  // ===== resetPassword START
  resetPassword(password, passwordConfirm, key) {
    let globals = Globals;

    let promise = new Promise((resolve, reject) => {
      if (password.length < 6) {
        reject({ error_msg: "A senha deve ter no mínimo 6 caracteres." });
      } else {
        // Check password and confirmation are the same
        if (password.value != passwordConfirm.value) {
          reject({ error_msg: "As senhas não conferem." });
        } else {
          let sha512 = SecureHashAlgorithm;
          var p = sha512.hex_sha512(password);

          let body = new URLSearchParams();
          body.append("action", "resetPassword");
          body.append("key", key);
          body.append("p", p);

          let BaseUrl = globals.uri + "common/api/user.php";

          fetch(BaseUrl, {
            method: "POST",
            body: body.toString(),
            headers: {
              "Content-Type": "application/x-www-form-urlencoded"
            },
            credentials: "same-origin"
          })
            .then(response => response.json())
            .then(responseJson => {
              // Success
              if (responseJson.success) {
                resolve(responseJson);
              } else {
                reject(responseJson);
              }
            })
            .catch(error => {
              reject(error);
            });
        }
      }
    });

    return promise;
  },
  // ===== resetPassword END

  // ===== resetPassword START
  find(token, userID) {
    let globals = Globals;

    let promise = new Promise((resolve, reject) => {
      let body = new URLSearchParams();
      body.append("action", "find");
      body.append("token", token);
      body.append("userID", userID);

      let BaseUrl = globals.uri + "common/api/user.php";

      fetch(BaseUrl, {
        method: "POST",
        body: body.toString(),
        headers: {
          "Content-Type": "application/x-www-form-urlencoded"
        },
        credentials: "same-origin"
      })
        .then(response => response.json())
        .then(responseJson => {
          // Success
          if (responseJson.success) {
            resolve(responseJson);
          } else {
            reject(responseJson);
          }
        })
        .catch(error => {
          reject(error);
        });
    });

    return promise;
  }
  // ===== resetPassword END
};

export default userService;
