import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import {
  Badge,
  UncontrolledDropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Nav,
  NavItem,
  NavLink
} from "reactstrap";
import PropTypes from "prop-types";

import {
  //  AppAsideToggler,
  AppHeaderDropdown,
  AppNavbarBrand,
  AppSidebarToggler
} from "@coreui/react";
import logo from "../../assets/img/brand/proassist-logo.png"; //"../../assets/img/brand/logo.svg";
import sygnet from "../../assets/img/brand/proassist-icon.png"; //"../../assets/img/brand/sygnet.svg";

import { connect } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import alertService from "../../services/alertService";
import Globals from "../../assets/js/globals.js";
import Languages from "../../assets/js/languageResources.js";

const propTypes = {
  children: PropTypes.node
};

const defaultProps = {};

class DefaultHeader extends Component {
  componentWillMount() {
    this.getAlerts();
  }

  getAlerts() {
    Globals.showLoader();
    alertService
      .getUnreadAlerts(localStorage.token)
      .then(result => {
        if (result.success) {
          this.setState({ alerts: result.alerts });
          const data = result.alerts;
          this.props.dispatch({
            type: "ALERTS",
            data
          });
        } else {
          toast.error(result.error_msg);
        }
        Globals.hideLoader();
      })
      .catch(err => {
        if (err.message) toast.error(err.message);
        if (err.error_msg) toast.error(err.error_msg);
        console.error(err);
        Globals.hideLoader();
      });
  }

  render() {
    if (!localStorage.user_id && window.location.hash.indexOf("login") === -1) {
      //console.log("DefaultHeader.js redirect");
      //window.location = "https://proassist.ninjaslist.com/home";
      //return <Redirect to={"/login"} />;
    }

    let RenderAlerts = props => {
      if (this.props.work.alerts) {
        return this.props.work.alerts.length;
      } else {
        return "";
      }
    };

    let RenderLoginLogout = props => {
      let icon = "";
      let text = "";
      if (localStorage.user_id) {
        icon = "fa fa-lock";
        text = Languages.getResource("btnLogout", localStorage.language);
      } else {
        icon = "fa fa-unlock";
        text = Languages.getResource("btnLogin", localStorage.language);
      }
      return (
        <NavLink href="#/login">
          <i className={icon} />
          {text}
        </NavLink>
      );
    };

    // eslint-disable-next-line
    const { children, ...attributes } = this.props;

    return (
      <React.Fragment>
        <AppSidebarToggler className="d-lg-none" display="md" mobile />
        <AppNavbarBrand
          full={{
            src: logo,
            width: 117,
            height: 25,
            alt: "ProAssist - Professional Assistant"
          }}
          minimized={{
            src: sygnet,
            width: 30,
            height: 30,
            alt: "ProAssist - Professional Assistant"
          }}
        />
        <AppSidebarToggler className="d-md-down-none" display="lg" />

        <Nav className="d-md-down-none" navbar>
          <NavItem className="px-3">
            <NavLink href="#/calendar">
              {Languages.getResource("calendar", localStorage.language)}
            </NavLink>
          </NavItem>
          <NavItem className="px-3">
            <NavLink href="#/dashboard">
              {Languages.getResource("dashboard", localStorage.language)}
            </NavLink>
          </NavItem>
        </Nav>
        <Nav className="ml-auto" navbar>
          <NavItem className="d-md-down-none">
            <NavLink href="#/dashboard">
              <i className="icon-bell" />
              <Badge pill color="danger">
                <RenderAlerts />
              </Badge>
            </NavLink>
          </NavItem>
          {/*}
          <NavItem className="d-md-down-none">
            <NavLink href="#">
              <i className="icon-list" />
            </NavLink>
          </NavItem>
          <NavItem className="d-md-down-none">
            <NavLink href="#">
              <i className="icon-location-pin" />
            </NavLink>
          </NavItem>
{*/}

          <AppHeaderDropdown direction="down">
            <UncontrolledDropdown>
              <DropdownToggle nav>
                <img
                  src={this.props.user.avatar}
                  className="img-avatar"
                  alt={this.props.user.name}
                />
              </DropdownToggle>
              <DropdownMenu right style={{ right: "auto" }}>
                <DropdownItem header tag="div" className="text-center">
                  <strong>
                    {Languages.getResource(
                      "lbl_account",
                      localStorage.language
                    )}
                  </strong>
                </DropdownItem>
                <DropdownItem>
                  <i className="fa fa-bell-o" />
                  &nbsp;{Languages.getResource("alerts", localStorage.language)}
                  <Badge color="danger">
                    <RenderAlerts />
                  </Badge>
                </DropdownItem>
                <DropdownItem>
                  <NavLink href={"#/person/" + localStorage.user_id}>
                    <i className="fa fa-user" /> Profile
                  </NavLink>
                </DropdownItem>
                {/*}
              <DropdownItem>
                <i className="fa fa-envelope-o" /> Messages
                <Badge color="success">42</Badge>
              </DropdownItem>              
              <DropdownItem>
                <i className="fa fa-bell-o" /> Updates
                <Badge color="info">42</Badge>
              </DropdownItem>
              <DropdownItem>
                <i className="fa fa-tasks" /> Tasks
                <Badge color="danger">42</Badge>
              </DropdownItem>
              <DropdownItem>
                <i className="fa fa-comments" /> Comments
                <Badge color="warning">42</Badge>
              </DropdownItem>
              <DropdownItem header tag="div" className="text-center">
                <strong>Settings</strong>
              </DropdownItem>
              <DropdownItem>
                <i className="fa fa-user" /> Profile
              </DropdownItem>
              <DropdownItem>
                <i className="fa fa-wrench" /> Settings
              </DropdownItem>
              <DropdownItem>
                <i className="fa fa-usd" /> Payments
                <Badge color="secondary">42</Badge>
              </DropdownItem>
              <DropdownItem>
                <i className="fa fa-file" /> Projects
                <Badge color="primary">42</Badge>
              </DropdownItem>
              <DropdownItem>
                <NavLink href="#/calendar">
                  <i className="fa fa-calendar" /> Calendar
                </NavLink>
              </DropdownItem>
              <DropdownItem divider />
              <DropdownItem>
                <i className="fa fa-shield" /> Lock Account
              </DropdownItem>
              <DropdownItem>
                <NavLink href="#/login">
                  <i className="fa fa-lock" /> Logout
                </NavLink>
              </DropdownItem>
              {*/}
                <DropdownItem>
                  <RenderLoginLogout />
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </AppHeaderDropdown>
        </Nav>
        {/*<AppAsideToggler className="d-md-down-none" />*/}
        {/*<AppAsideToggler className="d-lg-none" mobile />*/}
      </React.Fragment>
    );
  }
}

DefaultHeader.propTypes = propTypes;
DefaultHeader.defaultProps = defaultProps;

const mapStateToProps = state => {
  return {
    work: state
  };
};
export default connect(mapStateToProps)(DefaultHeader);
