import React, { Component } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { Container } from "reactstrap";

import {
  AppAside,
  AppBreadcrumb,
  AppFooter,
  AppHeader,
  AppSidebar,
  AppSidebarFooter,
  AppSidebarForm,
  AppSidebarHeader,
  AppSidebarMinimizer,
  AppSidebarNav
} from "@coreui/react";
// sidebar nav config
import navigation from "../../_nav";
// routes config
import routes from "../../routes";
import DefaultAside from "./DefaultAside";
import DefaultFooter from "./DefaultFooter";
import DefaultHeader from "./DefaultHeader";

class DefaultLayout extends Component {
  constructor(props) {
    super(props);

    if (localStorage.hasOwnProperty("user_data")) {
      let user_data = JSON.parse(localStorage.user_data);
      //this.changeUser(user_data.avatar, user_data.name);
      if (user_data.avatar !== "") {
        this.state = {
          user: {
            avatar: user_data.avatar,
            name: user_data.name
          }
        };
      } else {
        this.state = {
          user: {
            avatar: "assets/img/avatars/user.png",
            name: user_data.name
          }
        };
      }
    } else {
      this.state = {
        user: {
          avatar: "assets/img/avatars/user.png",
          name: ""
        }
      };
    }

    if (
      localStorage.token == undefined &&
      window.location.hash.indexOf("login") === -1
    ) {
      //console.log("DefaultLayout.js redirect");
      //window.location = "https://proassist.ninjaslist.com/home";
      //this.props.history.push("/login");
      return;
    }
  }

  componentWillMount() {}

  changeUser(avatar, name) {
    this.setState({
      user: {
        avatar: avatar,
        name: name
      }
    });
  }

  render() {
    return (
      <div className="app">
        <div className="loader-holder" id="loader">
          <div className="loader-circle" />
        </div>
        <AppHeader fixed>
          <DefaultHeader user={this.state.user} />
        </AppHeader>
        <div className="app-body">
          <AppSidebar fixed display="lg">
            <AppSidebarHeader />
            <AppSidebarForm />
            <AppSidebarNav navConfig={navigation} {...this.props} />
            <AppSidebarFooter />
            <AppSidebarMinimizer />
          </AppSidebar>
          <main className="main">
            <AppBreadcrumb appRoutes={routes} />
            <Container fluid>
              <Switch>
                {routes.map((route, idx) => {
                  return route.component ? (
                    <Route
                      key={idx}
                      path={route.path}
                      exact={route.exact}
                      name={route.name}
                      render={props => (
                        <route.component
                          {...props}
                          user={this.state.user}
                          changeUser={this.changeUser.bind(this)}
                        />
                      )}
                    />
                  ) : null;
                })}
                <Redirect from="/" to="/dashboard" />
              </Switch>
            </Container>
          </main>
          <AppAside fixed>
            <DefaultAside />
          </AppAside>
        </div>
        <AppFooter>
          <DefaultFooter />
        </AppFooter>
      </div>
    );
  }
}

export default DefaultLayout;
