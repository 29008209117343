import Globals from "../assets/js/globals.js";

let alertService = {
  // ===== find START
  getUnreadAlerts(token) {
    let globals = Globals;

    let promise = new Promise((resolve, reject) => {
      if (token) {
        let data = {
          action: "getUnreadAlerts",
          token: token
        };
        let BaseUrl = globals.uri + "common/api/alert.php";

        fetch(BaseUrl, {
          method: "POST",
          body: JSON.stringify(data),
          headers: {
            "Content-Type": "application/json"
          },
          credentials: "same-origin"
        })
          .then(response => response.json())
          .then(responseJson => {
            // Success
            if (responseJson.success) {
              resolve(responseJson);
            } else {
              reject({
                error_msg: responseJson.error_msg
                  ? responseJson.error_msg
                  : responseJson.error
                  ? responseJson.error
                  : "Error getting alerts"
              });
            }
          })
          .catch(error => {
            reject(error);
          });
      } else {
        reject("Please log in");
      }
    });
    return promise;
  },
  // ===== find END

  // ===== setAlertRead START
  setAlertRead(token, alert_group_id, language) {
    let globals = Globals;

    let promise = new Promise((resolve, reject) => {
      let data = {
        action: "setAlertRead",
        token: token,
        alert_group_id: alert_group_id,
        language: language
      };
      let BaseUrl = globals.uri + "common/api/alert.php";

      fetch(BaseUrl, {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json"
        },
        credentials: "same-origin"
      })
        .then(response => response.json())
        .then(responseJson => {
          // Success
          if (responseJson.success) {
            resolve(responseJson);
          } else {
            reject({
              error_msg: responseJson.error_msg
                ? responseJson.error_msg
                : responseJson.error
                ? responseJson.error
                : "Error getting alerts"
            });
          }
        })
        .catch(error => {
          reject(error);
        });
    });
    return promise;
  }
  // ===== setAlertRead END
};

export default alertService;
