const workReducer = (state = [], action) => {
  switch (action.type) {
    case "WORK": {
      state = action.data;
      return state;
    }
    case "ADD_WORK":
      return state.concat([action.data]);
    case "ALERTS": {
      state = { alerts: action.data };
      return state;
    }
    case "ADD_ALERT":
      return state.alerts.concat([action.data]);
    case "BILL": {
      state.bill = action.data;
      return state;
    }
    default:
      return state;
  }
};
export default workReducer;
