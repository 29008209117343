import React from "react";

const InputText = props => {
  const disabledprop = props.disabled === "1" ? "disabled" : "";
  return (
    <div className="position-relative form-group">
      <label htmlFor={props.name}>{props.title}</label>
      <input
        className="form-control"
        id={props.name}
        name={props.name}
        type={props.type}
        value={props.value}
        defaultValue={props.defaultValue}
        onBlur={props.handleBlur}
        onChange={props.handleChange}
        onKeyUp={props.onKeyUp}
        placeholder={props.placeholder}
        disabled={disabledprop}
      />
    </div>
  );
};

export default InputText;
