import React, { Component } from "react";
import { HashRouter, Route, Switch } from "react-router-dom";
import "./App.scss";

// Containers
import { DefaultLayout } from "./containers";
// Pages
import {
  Login,
  Page404,
  Page500,
  Register,
  ResetPassword
} from "./views/Pages";

// import { renderRoutes } from 'react-router-config';

import Globals from "./assets/js/globals.js";
// Configure Moment to different locales
import moment from "moment";
require("moment/min/locales.min");
let language = localStorage.language
  ? localStorage.language
  : Globals.languageDefault;
moment.locale(language);

if (!localStorage.user_id && window.location.hash.indexOf("login") === -1) {
  console.log("app.js redirect");
  if (window.location.hostname != "localhost") {
    if (!window.location.href.includes("reset-password") ) {
      window.location = "https://proassist.ninjaslist.com/home";
    }
  }
}

class App extends Component {
  render() {
    return (
      <HashRouter>
        <Switch>
          <Route exact path="/login" name="Login Page" component={Login} />
          <Route
            exact
            path="/register"
            name="Register Page"
            component={Register}
          />
          <Route exact path="/404" name="Page 404" component={Page404} />
          <Route exact path="/500" name="Page 500" component={Page500} />
          <Route
            exact
            path="/reset-password/:key"
            name="Reset Password"
            component={ResetPassword}
          />
          <Route path="/" name="Home" component={DefaultLayout} />
          <Route
            path="/home"
            name="Site"
            component={() =>
              (window.location = "https://proassist.ninjaslist.com/home")
            }
          />
        </Switch>
      </HashRouter>
    );
  }
}

export default App;
