import React, { Component } from "react";
import GoogleLogin from "react-google-login";
import FacebookLogin from "react-facebook-login";
import userService from "../../../services/userService";
import { Redirect } from "react-router-dom";
import "./custom.css";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  Col,
  Container,
  Form,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row
} from "reactstrap";
import Languages from "../../../assets/js/languageResources.js";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

class ResetPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      password: "",
      passwordConfirm: "",
      key: "",
      redirect: false
    };
    this.resetPassword = this.resetPassword.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  componentDidMount() {
    const key = this.props.match.params.key;
    if (key) {
      this.setState({ key: key });
    }
  }

  showLoader() {
    document.getElementById("loader-login").style.display = "block";
  }
  hideLoader() {
    document.getElementById("loader-login").style.display = "none";
  }

  resetPassword() {
    if (this.state.passwordConfirm && this.state.password) {
      userService
        .resetPassword(
          this.state.password,
          this.state.passwordConfirm,
          this.state.key
        )
        .then(result => {
          if (result.success) {
            this.setState({ redirect: true });
          }
        })
        .catch(err => {
          console.error(err);
          toast.error(err.error_msg);
          this.hideLoader();
        });
    }
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  render() {
    if (this.state.redirect) {
      return <Redirect to={"/"} />;
    }

    if (sessionStorage.getItem("user_data")) {
      return <Redirect to={"/"} />;
    }

    return (
      <div>
        <div className="loader-holder" id="loader-login">
          <div className="loader-circle" />
        </div>
        <ToastContainer />
        <div className="app flex-row align-items-center">
          <Container>
            <Row className="justify-content-center">
              <Col md="6">
                <Card className="mx-4">
                  <CardBody className="p-4">
                    <Form>
                      <h1>
                        {Languages.getResource(
                          "lbl_reset_password",
                          localStorage.language
                        )}
                      </h1>

                      <InputGroup className="mb-3">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="icon-lock" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          type="password"
                          name="password"
                          placeholder={Languages.getResource(
                            "password",
                            localStorage.language
                          )}
                          autoComplete="new-password"
                          onChange={this.onChange}
                        />
                      </InputGroup>
                      <InputGroup className="mb-4">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="icon-lock" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          type="password"
                          name="passwordConfirm"
                          placeholder={
                            Languages.getResource(
                              "confirm",
                              localStorage.language
                            ) +
                            " " +
                            Languages.getResource(
                              "password",
                              localStorage.language
                            )
                          }
                          autoComplete="new-password"
                          onChange={this.onChange}
                        />
                      </InputGroup>
                      <Button
                        color="warning"
                        block
                        onClick={this.resetPassword}
                      >
                        {Languages.getResource(
                          "lbl_reset_password",
                          localStorage.language
                        )}
                      </Button>
                    </Form>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    );
  }
}

export default ResetPassword;
